// Angular Modules
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

// External Modules
import { forkJoin, map, Observable, of, switchMap } from 'rxjs';

// Models
import { QueryParameters, QueryResults } from '@models/index';
import { Broker } from '@mqtt/brokers/models/index';

// Services
import { HttpQueryUtils } from '@services/http-query-utils.service';

const BACKEND_API = `${environment.apiBaseUrl}`;
const ENTITY = 'broker'


@Injectable({
  providedIn: 'root'
})
export class BrokerService {

  constructor(
    private http: HttpClient,
    public httpQueryUtils: HttpQueryUtils) { }

  // get httpParams() {
  //   return new HttpParams()//.set('fields', 'name,capital,alpha2Code,flags.png,population');
  // }


  find(queryParameters: QueryParameters): Observable<QueryResults<Broker>> {

    const url = `${BACKEND_API}/${ENTITY}/`;

    const queryResults$ = this.httpQueryUtils.executeQuery<Broker>(url, queryParameters);

    return queryResults$;
  }


  delete(id: number): Observable<any> {

    const url = `${BACKEND_API}/${ENTITY}/${id}/`;

    return this.http.delete(url);
  }


  deleteBulk(ids: Array<number>): Observable<any> {

    const colObservablesToDelete= [];

    for (const id of ids) {
      colObservablesToDelete.push(this.delete(id));
    }

    return forkJoin( colObservablesToDelete );
  }


  add(entity: Broker): Observable<Broker> {

    const url = `${BACKEND_API}/${ENTITY}/`;

    return this.http.post<Broker>(url, entity);
  }


  update(entity: Broker): Observable<Broker> {

    const url = `${BACKEND_API}/${ENTITY}/${entity.id}/`;

    return this.http.put<Broker>(url, entity);
  }


  findById(id: number): Observable<Broker> {

    const url = `${BACKEND_API}/${ENTITY}/${id}/`;
    return this.httpQueryUtils.executeQuery<Broker>(url, {})
      .pipe(
        map(result => result.items[0])
      )
  }

}
